import moment from 'moment-timezone';

export function intoMoment(
	date?: moment.Moment | Date | string | null | Array<unknown>,
	tz?: string,
): moment.Moment | null {
	if (Array.isArray(date)) return null;

	const momentDate = moment(date);
	if (tz) momentDate.tz(tz);

	if (!momentDate.isValid()) return null;

	return momentDate;
}
