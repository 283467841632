import Booking from 'src/entities/booking/Booking';
import IdName from 'src/entities/IdName';
import { InsuranceState } from 'src/entities/insurance/Insurance';
import { fromJsonArray } from 'src/helper/helper';
import BookingRowList from '@/entities/booking/BookingRowList';

export default class BookingList extends Booking {
	integration?: IdName;
	account?: any;
	bookingRows: BookingRowList[];
	insuranceState: InsuranceState;
	cancelled: boolean;
	changedExternally: boolean;
	companyName: string;

	constructor(json: any) {
		super(json);
		this.integration = json.integration ? new IdName(json.integration) : undefined;
		this.account = json.account;
		this.bookingRows = fromJsonArray(BookingRowList, json.bookingRows);
		this.insuranceState = json.insuranceState;
		this.cancelled = json.cancelled;
		this.changedExternally = json.changedExternally;
		this.companyName = json.companyName;
	}

	getFirstRow(): BookingRowList {
		return this.bookingRows[0];
	}
}
