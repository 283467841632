import { UnknownAction } from '@reduxjs/toolkit';
import { TOptions } from 'i18next';
import { RouteComponentProps } from 'react-router-dom';
import BaseEntity from 'src/entities/BaseEntity';
import Account from 'src/entities/accounts/Account';
import { AccountFull } from 'src/entities/accounts/AccountFull';
import BookingProgress from 'src/entities/booking/progress/BookingProgress';
import EntityDescription, { Projection } from 'src/entities/description/EntityDescription';
import { APIError } from 'src/entities/error/APIError';
import EventBooking from 'src/entities/event/EventBooking';
import { Theme } from 'src/entities/integration/Integration';
import Pagination from 'src/entities/pagination/Pagination';
import { FetchDescription } from 'src/redux/actions';
import { SearchFilterState } from 'src/redux/reducers/search-filter';
import { CompiledRoutes } from 'src/routing';
import { Breadcrumb } from 'src/routing/BreadcrumbWrapper';

/**
 * Redux Store State
 */
export interface StoreState {
	entities: any;
	notification: Notification;
	ownAccount: SingleEntityState<AccountFull>;
	activeTabIndex: number;
	searchResults: EventBooking[];
	form: any;
	booking: SingleEntityState<BookingProgress>;
	searchFilterState: SearchFilterState;
	theme: ThemeState;
	breadcrumbs: BreadcrumbState;
}

/**
 * State of entities collection in redux store
 */
export interface EntityCollectionState<G> {
	isFetching: boolean;
	error?: APIError;
	pagination?: Pagination;
	items: G[];
}

/**
 * State of single entity
 */
export interface SingleEntityState<G> {
	isFetching: boolean;
	error?: APIError;
	content?: G;
}

export type ParsedUrlQuery = Record<string, string | string[] | undefined>;

/**
 * Defines current theme and the previous used them so we can
 */
export interface ThemeState {
	currentTheme: Theme;
	previousTheme?: Theme;
}

/**
 * Defines current breadcrumbs
 */
export interface BreadcrumbState {
	raw: Breadcrumb[];
	resolved: Breadcrumb[];
}

/**
 * Represents key value pairs as strings for request headers
 */
export type RequestHeaders = Record<string, string>;

export interface CRUActionProps {
	fetchEntities: (
		entityDescription: EntityDescription,
		projection?: Projection,
		searchMethod?: string,
		publicEndpoint?: boolean,
	) => UnknownAction;
	fetchEntity: (entityDescription: EntityDescription, id: number, projection?: Projection) => UnknownAction;
	fetchEntitiesByAccount: (
		entityDescription: EntityDescription,
		account: Account,
		projection?: Projection,
	) => UnknownAction;
	createEntity: (
		entityDescription: EntityDescription,
		body: any,
		projection?: Projection,
		fetchAfterSuccess?: FetchDescription[],
	) => UnknownAction;
	patchEntity: (
		entityDescription: EntityDescription,
		body: any,
		projection?: Projection,
		fetchAfterSuccess?: FetchDescription[],
		patchMethod?: string,
	) => UnknownAction;
}

export interface CRUDActionProps extends CRUActionProps {
	deleteEntity: (
		entityDescription: EntityDescription,
		id: number,
		projection?: Projection,
		fetchAfterSuccess?: FetchDescription[],
		actionAfterSuccess?: (entity: BaseEntity) => void,
	) => UnknownAction;
}

export interface InjectedRouteComponentProps {
	routeComponentProps: RouteComponentProps<any>;
	account: SingleEntityState<AccountFull>;
	entityDescription?: EntityDescription;
	routes: CompiledRoutes;
	contextId?: string;
}

export enum StatusType {
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	INFO = 'INFO',
	SUCCESS = 'SUCCESS',
}

export interface AdditionalNotificationProps {
	id: number;
	projection: Projection;
}

export interface Notification {
	notificationType: StatusType;
	statusCode: number;
	requestMethod: string;
	entityDescription?: EntityDescription;
	key?: string;
	options?: TOptions;
	additionalNotificationProps?: AdditionalNotificationProps;
}

export type InterpolationMap = Record<string, string>;
