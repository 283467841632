import { useSelector } from 'react-redux';
import { LayoutProps } from 'src/components/layout/Layout';
import { SupportedLanguage } from 'src/config/i18next/i18next';
import { Theme } from 'src/entities/integration/Integration';
import { StoreState } from 'src/types';

const LAYOUT_PROPS: Record<string, Partial<LayoutProps>> = {
	[Theme.DEFAULT]: {
		stickyContainer: false,
	},
	[Theme.BUSFINDER]: {
		stickyContainer: false,
	},
	[Theme.BUSFINDEREVENTS]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
	},
	[Theme.PENNY]: {
		stickyContainer: true,
		disableSticky: true,
	},
	[Theme.BILLA]: {
		stickyContainer: true,
		disableSticky: true,
	},
	[Theme.MERKUR]: {
		stickyContainer: true,
		disableSticky: true,
	},
	[Theme.ZUCHIBUS]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
	},
	[Theme.FISCHWENGER]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
	},
	[Theme.SCHARINGER]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
	},
	[Theme.GERNGROSS]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
	},
	[Theme.REDBULL]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
		footerVisible: true,
	},
	[Theme.AIRPOWER]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
		footerVisible: true,
	},
	[Theme.FEINS]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
		footerVisible: true,
	},
	[Theme.RBPFEIFER]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
		footerVisible: true,
	},
	[Theme.THV]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
		footerVisible: true,
	},
	[Theme.SKIAUSFAHRTEN]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
		footerVisible: true,
	},
	[Theme.ACTIVETIMES]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
		footerVisible: true,
	},
	[Theme.OLIVAREISEN]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
	},
	[Theme.BEACHCAMP]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
	},
	[Theme.ROLLINGLOUD]: {
		stickyContainer: false,
		mainContainer: false,
		navContainer: false,
		footerContainer: false,
		headerContainer: false,
		disableSticky: true,
		footerVisible: true,
	},
	[Theme.BAUMFRIEDREISEN]: {
		stickyContainer: false,
	},
	[Theme.FASCHLATZER]: {
		stickyContainer: false,
	},
};

export const getThemeLayoutProps = (theme: string): Partial<LayoutProps> => {
	return LAYOUT_PROPS[theme] ?? LAYOUT_PROPS[Theme.DEFAULT];
};

export interface AppProps {
	disableLogin: boolean;
	disableCompanyRating: boolean;
	disableCouponInput: boolean;
	forceLanguage?: SupportedLanguage;
	// Do not use this PLS; This is just a hack to a wrong price setup; DD
	hideOptionalTicketProductsHeader?: boolean;
	hideRelatedOptionalTicketProductsHeader?: boolean;
	// Also a hacky feature where we want to assign some flags to a booking
	bookingMetaFlags?: string[];
	// Another hack to remove all associations of bus journeys
	// ex. Bus Journey -> Journey
	// Because some air journeys ended up as bus journey from CSBus
	agnosticJourneys?: boolean;
	// Always show abs prices. That is basePrice plus surcharges
	showAbsPrices?: boolean;
	hidePhoneAtInsuranceStep?: boolean;
	// Disable p.P. Price labels
	disablePPPriceLabels?: boolean;
	// Hide Price until the last product step is completed (usually pick up points)
	hidePriceUntilLastStep?: boolean;
	// Mirror Booking Control Buttons on top; This also removes back button in steps
	mirrorBookingControls?: boolean;
}

const APP_PROPS: Record<string, AppProps> = {
	[Theme.DEFAULT]: {
		disableLogin: false,
		disableCompanyRating: false,
		disableCouponInput: false,
	},
	[Theme.BUSFINDER]: {
		disableLogin: false,
		disableCompanyRating: false,
		disableCouponInput: false,
	},
	[Theme.BUSFINDEREVENTS]: {
		disableLogin: false,
		disableCompanyRating: false,
		disableCouponInput: false,
		disablePPPriceLabels: true,
		mirrorBookingControls: true,
		showAbsPrices: true,
		hidePriceUntilLastStep: true,
	},
	[Theme.PENNY]: {
		disableLogin: true,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'de',
	},
	[Theme.BILLA]: {
		disableLogin: true,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'de',
	},
	[Theme.MERKUR]: {
		disableLogin: true,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'de',
	},
	[Theme.ZUCHIBUS]: {
		disableLogin: true,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'de',
	},
	[Theme.FUCHS]: {
		disableLogin: true,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'en',
	},
	[Theme.FISCHWENGER]: {
		disableLogin: false,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'de',
	},
	[Theme.SCHARINGER]: {
		disableLogin: false,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'de',
	},
	[Theme.GERNGROSS]: {
		disableLogin: false,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'de',
	},
	[Theme.REDBULL]: {
		disableLogin: true,
		disableCompanyRating: false,
		disableCouponInput: false,
		hideOptionalTicketProductsHeader: true,
		hideRelatedOptionalTicketProductsHeader: true,
	},
	[Theme.AIRPOWER]: {
		disableLogin: true,
		disableCompanyRating: false,
		disableCouponInput: false,
		hideOptionalTicketProductsHeader: true,
		hideRelatedOptionalTicketProductsHeader: true,
		showAbsPrices: true,
		disablePPPriceLabels: true,
		hidePriceUntilLastStep: true,
		mirrorBookingControls: true,
	},
	[Theme.FEINS]: {
		disableLogin: true,
		disableCompanyRating: true,
		disableCouponInput: true,
		hideOptionalTicketProductsHeader: true,
		hideRelatedOptionalTicketProductsHeader: true,
		bookingMetaFlags: [
			'start_finish_tribune',
			'red_bull_tribune',
			'styria_tribune',
			'ktm_tribune',
			't10_tribune',
			'north_tribune',
			'standing_area',
		],
		showAbsPrices: true,
		disablePPPriceLabels: true,
		hidePriceUntilLastStep: true,
		mirrorBookingControls: true,
	},
	[Theme.RBPFEIFER]: {
		disableLogin: true,
		disableCouponInput: false,
		disableCompanyRating: false,
	},
	[Theme.THV]: {
		disableLogin: true,
		disableCouponInput: false,
		disableCompanyRating: false,
	},
	[Theme.SKIAUSFAHRTEN]: {
		disableLogin: true,
		disableCouponInput: true,
		disableCompanyRating: false,
	},
	[Theme.ACTIVETIMES]: {
		disableLogin: true,
		disableCouponInput: false,
		disableCompanyRating: false,
	},
	[Theme.OLIVAREISEN]: {
		disableLogin: true,
		disableCompanyRating: true,
		disableCouponInput: true,
		agnosticJourneys: true,
	},
	[Theme.BEACHCAMP]: {
		disableLogin: true,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'de',
		showAbsPrices: true,
		hidePhoneAtInsuranceStep: true,
	},
	[Theme.GITSCHTALREISEN]: {
		disableLogin: true,
		disableCompanyRating: false,
		disableCouponInput: false,
		forceLanguage: 'de',
	},
	[Theme.ROLLINGLOUD]: {
		disableLogin: true,
		disableCompanyRating: true,
		disableCouponInput: true,
		hideOptionalTicketProductsHeader: true,
		hideRelatedOptionalTicketProductsHeader: true,
		showAbsPrices: true,
		disablePPPriceLabels: true,
		hidePriceUntilLastStep: true,
		mirrorBookingControls: true,
	},
	[Theme.BAUMFRIEDREISEN]: {
		disableLogin: true,
		disableCouponInput: false,
		disableCompanyRating: false,
	},
    [Theme.FASCHLATZER]: {
		disableLogin: true,
		disableCouponInput: false,
		disableCompanyRating: false,
	},
};

export const getThemedAppProps = (theme: string): AppProps => {
	return APP_PROPS[theme] ?? APP_PROPS[Theme.DEFAULT];
};

export function useAppProps() {
	const theme = useSelector((state: StoreState) => state.theme.currentTheme);

	return APP_PROPS[theme] ?? APP_PROPS[Theme.DEFAULT];
}
