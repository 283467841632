import EntityDescription from 'src/entities/description/EntityDescription';
import { EntityDescriptions } from 'src/entities/description';
import ReviewFull from 'src/entities/review/ReviewFull';
import moment from 'moment-timezone';
import IdName from '@/entities/IdName';

export default class BookingRowList {
	pax: number;
	cancelled: boolean;
	readonly cancelledAt?: moment.Moment;
	review?: ReviewFull;
	bundle: IdName;

	constructor(json: any) {
		this.pax = Number(json.pax);
		this.cancelled = json.cancelled;
		this.cancelledAt = json.cancelledAt ? moment(json.cancelledAt) : undefined;
		this.review = json.review ? new ReviewFull(json.review) : undefined;
		this.bundle = new IdName(json.bundle);
	}

	toString(): string {
		return String(this.pax);
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.BOOKING_ROW;
	}
}
