import { de } from 'date-fns/locale/de';
import { registerLocale } from 'react-datepicker';
import { setupIframeResizer } from 'src/config/integration/integration';
import { bootstrapEntities } from 'src/entities/description';
import { bootstrapProjections } from 'src/entities/description/projections';
import { initSentry } from './sentry';

initSentry();
registerLocale('de', de);
bootstrapEntities();
bootstrapProjections();
setupIframeResizer();
