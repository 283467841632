import BaseEntity, { AutoFormFields } from 'src/entities/BaseEntity';
import ContactData from 'src/entities/basic-types/ContactData';
import Document from 'src/entities/basic-types/Document';
import Location from 'src/entities/basic-types/Location';
import SEO from 'src/entities/basic-types/SEO';
import { EntityDescriptions } from 'src/entities/description';
import EntityDescription from 'src/entities/description/EntityDescription';
import SocialMedia from '../basic-types/SocialMedia';

/**
 * The associated strings for each variant has
 * to match the folder name under /src/semantic and /src/themes.
 * Case is not important as it gets .toLowerCase()'ed.
 * Note: These strings need to match the ones saved in the DB
 * as it will be checked weather some value is a member of this
 * Enum and for that case is important. (Could be changed though)
 */
export enum Theme {
	// noinspection SpellCheckingInspection
	BACKEND = 'BACKEND',
	DEFAULT = 'DEFAULT',
	BUSREISEBOX = 'BUSREISEBOX',
	BUSFINDER = 'BUSFINDER',
	BUSFINDEREVENTS = 'BUSFINDEREVENTS',
	PENNY = 'PENNY',
	BILLA = 'BILLA',
	MERKUR = 'MERKUR',
	ZUCHIBUS = 'ZUCHIBUS',
	FUCHS = 'FUCHS',
	FISCHWENGER = 'FISCHWENGER',
	SCHARINGER = 'SCHARINGER',
	GERNGROSS = 'GERNGROSS',
	REDBULL = 'REDBULL',
	AIRPOWER = 'AIRPOWER',
	FEINS = 'FEINS',
	RBPFEIFER = 'RBPFEIFER',
	THV = 'THV',
	SKIAUSFAHRTEN = 'SKIAUSFAHRTEN',
	ACTIVETIMES = 'ACTIVETIMES',
	OLIVAREISEN = 'OLIVAREISEN',
	BEACHCAMP = 'BEACHCAMP',
	GITSCHTALREISEN = 'GITSCHTALREISEN',
	ROLLINGLOUD = 'ROLLINGLOUD',
	BAUMFRIEDREISEN = 'BAUMFRIEDREISEN',
	FASCHLATZER = 'FASCHLATZER',
}

export const themeMenuItems = Object.keys(Theme)
	.filter(theme => theme !== Theme.BACKEND)
	.map((theme, index) => ({
		key: index,
		value: theme,
		text: theme,
	}));

export default class Integration extends BaseEntity {
	readonly name: string;
	readonly theme: Theme;
	readonly logo?: Document;

	readonly url: string;
	readonly urlAuthority: string;

	readonly seo: SEO;
	readonly socialMedia: SocialMedia;
	readonly owner: ContactData;
	readonly location: Location;
	readonly locale: string;
	readonly from: string;

	readonly external: boolean;
	readonly iframe: boolean;
	readonly isolatedBookingProgress: boolean;
	readonly disableBookingConfirmationMails: boolean;
	readonly disableInsuranceStep: boolean;
	readonly onlinePayment: boolean;
	readonly acceptPrivacyPolicy: boolean;
	readonly acceptNewsletter: boolean;

	readonly ownerCommission: number;
	readonly commission: number;

	readonly generalConditions: string;
	readonly packageTourInfo: string;

	readonly bookingConfirmationTitleText: Record<string, string>;
	readonly bookingConfirmationSalutationText: Record<string, string>;
	readonly bookingConfirmationTopText: Record<string, string>;
	readonly bookingConfirmationBottomText: Record<string, string>;
	readonly bookingConfirmationSignatureClosingText: Record<string, string>;
	readonly bookingConfirmationSignatureText: Record<string, string>;
	readonly childCheckboxText: Record<string, string>;

	constructor(json: any) {
		super(json);

		this.name = json.name;
		this.theme = json.theme;
		this.logo = Document.fromJson(json.logo);

		this.url = json.url;
		this.urlAuthority = json.urlAuthority;

		this.seo = SEO.fromJson(json.seo);
		this.socialMedia = SocialMedia.fromJson(json.socialMedia);
		this.owner = ContactData.fromJson(json.owner);
		this.location = Location.fromJson(json.location)!;
		this.locale = json.locale;
		this.from = json.from;

		this.external = json.external;
		this.iframe = json.iframe;
		this.isolatedBookingProgress = json.isolatedBookingProgress;
		this.disableBookingConfirmationMails = json.disableBookingConfirmationMails;
		this.disableInsuranceStep = json.disableInsuranceStep;
		this.onlinePayment = json.onlinePayment;
		this.acceptPrivacyPolicy = json.acceptPrivacyPolicy;
		this.acceptNewsletter = json.acceptNewsletter;

		this.ownerCommission = json.ownerCommission;
		this.commission = json.commission;

		this.generalConditions = json.generalConditions;
		this.packageTourInfo = json.packageTourInfo;

		this.bookingConfirmationTitleText = json.bookingConfirmationTitleText;
		this.bookingConfirmationSalutationText = json.bookingConfirmationSalutationText;
		this.bookingConfirmationTopText = json.bookingConfirmationTopText;
		this.bookingConfirmationBottomText = json.bookingConfirmationBottomText;
		this.bookingConfirmationSignatureClosingText = json.bookingConfirmationSignatureClosingText;
		this.bookingConfirmationSignatureText = json.bookingConfirmationSignatureText;
		this.childCheckboxText = json.childCheckboxText;
	}

	toString(): string {
		return this.name;
	}

	getEntityDescription(): EntityDescription {
		return EntityDescriptions.INTEGRATION;
	}

	showChildCheckbox(): boolean {
		return !this.disableInsuranceStep || Object.values(this.childCheckboxText).some(txt => txt !== '');
	}

	getOwnerName(): string {
		return this.owner.companyData.name || this.name;
	}

	static formFields(entity?: Integration): AutoFormFields<Integration> {
		return {
			...BaseEntity.formFields(entity),

			name: entity?.name,
			theme: entity?.theme,
			logo: entity?.logo,

			url: entity?.url,
			urlAuthority: entity?.urlAuthority,

			seo: SEO.formFields(entity?.seo),
			socialMedia: SocialMedia.formFields(entity?.socialMedia),
			owner: ContactData.formValues(entity?.owner),
			location: entity?.location,
			locale: entity?.locale,
			from: entity?.from,

			external: entity?.external,
			iframe: entity?.iframe,
			isolatedBookingProgress: entity?.isolatedBookingProgress,
			disableBookingConfirmationMails: entity?.disableBookingConfirmationMails,
			disableInsuranceStep: entity?.disableInsuranceStep,
			onlinePayment: entity?.onlinePayment,
			acceptPrivacyPolicy: entity?.acceptPrivacyPolicy,
			acceptNewsletter: entity?.acceptNewsletter,

			ownerCommission: entity?.ownerCommission,
			commission: entity?.commission,

			generalConditions: entity?.generalConditions,
			packageTourInfo: entity?.packageTourInfo,

			bookingConfirmationTitleText: entity?.bookingConfirmationTitleText,
			bookingConfirmationSalutationText: entity?.bookingConfirmationSalutationText,
			bookingConfirmationTopText: entity?.bookingConfirmationTopText,
			bookingConfirmationBottomText: entity?.bookingConfirmationBottomText,
			bookingConfirmationSignatureClosingText: entity?.bookingConfirmationSignatureClosingText,
			bookingConfirmationSignatureText: entity?.bookingConfirmationSignatureText,
			childCheckboxText: entity?.childCheckboxText,
		};
	}
}
