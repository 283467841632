import moment from 'moment-timezone';
import 'node_modules/react-datepicker/dist/react-datepicker.css';
import { FC, FocusEvent } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Input, SemanticICONS } from 'semantic-ui-react';
import 'src/components/elements/datetime/DateTimePicker.css';
import { intoMoment } from './utils';

interface DatePickerProps {
	value?: moment.Moment;
	onChange?: (date: moment.Moment | null) => void;
	onBlur?: (event?: FocusEvent) => void;
	disabled?: boolean;
	minDateTime?: moment.Moment;
	maxDateTime?: moment.Moment;
	placeholder?: string;
	fluid?: boolean;
	icon?: SemanticICONS;
	iconPosition?: 'left';
	size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive';
	transparent?: boolean;
	isClearable?: boolean;
	yearDropdownItemNumber?: number;
}

export const DatePicker: FC<DatePickerProps> = ({
	value,
	onChange,
	onBlur,
	disabled,
	placeholder,
	fluid,
	icon,
	iconPosition,
	size,
	transparent,
	isClearable,
	minDateTime,
	maxDateTime,
	yearDropdownItemNumber,
}) => {
	const { i18n } = useTranslation();
	return (
		<ReactDatePicker
			selected={value?.toDate()}
			locale={i18n.language}
			dateFormat="P"
			timeIntervals={15}
			preventOpenOnFocus
			placeholderText={placeholder}
			onChange={(date: Date) => onChange?.(intoMoment(date, value?.tz()))}
			onCalendarClose={() => onBlur?.()}
			disabled={disabled}
			isClearable={isClearable}
			showYearDropdown
			showMonthDropdown
			scrollableYearDropdown
			useShortMonthInDropdown
			yearDropdownItemNumber={yearDropdownItemNumber}
			minDate={minDateTime?.toDate()}
			maxDate={maxDateTime?.toDate()}
			disabledKeyboardNavigation={true}
			customInput={
				<Input fluid={fluid} icon={icon} iconPosition={iconPosition} size={size} transparent={transparent} />
			}
			popperProps={{
				strategy: 'fixed',
			}}
		/>
	);
};
